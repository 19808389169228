import { inject } from 'vue';

export default {
  data() {
    const competition = inject('competition', '');
    console.log(competition)
    if (competition === 'wrc') {
      return {
        competition: "wrc",
        hexCodePrimary: "#fc4c02",
        hexCodePrimaryWrc: "#fc4c02",
        hexCodeSecondary: "#1f2a44",
        competitionLogoPath: 'img/WRC_LOGO.png',
        competitionBaseUrl: 'https://www.wrc.com'
      };
    }
    if (competition === 'erc') {
      return {
        competition: "erc",
        hexCodePrimary: "#0072ce",
        hexCodePrimaryWrc: "#fc4c02",
        hexCodeSecondary: "#1f2a44",
        competitionLogoPath: 'img/ERC_LOGO.png',
        competitionBaseUrl: 'https://www.fiaerc.com'
      };
    }
    if(competition === 'wrx'){
      return {
        competition: "wrx",
        hexCodePrimary: "#02f3eb",
        hexCodePrimaryWrc: "#fc4c02",
        hexCodeSecondary: "#1f2a44",
        competitionLogoPath: 'img/WRX_LOGO.png',
        competitionBaseUrl: "https://www.fiaworldrallycross.com"
      };
    }
  },
  methods: {
    urlPath(path) {
      return `https://d2b2fa8ekyxvhl.cloudfront.net/assets/${path}`;
    },
    loadSvgHtml(svgPath, targetElementId) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.urlPath(svgPath), false);
      xhr.overrideMimeType("image/svg+xml");
      xhr.onload = function () {
        document.getElementById(targetElementId).appendChild(xhr.responseXML.documentElement);
      };
      xhr.send("");
    },
    loadImage(imagePath, targetElementId) {
      const img = new Image();
      img.src = this.urlPath(imagePath);

      img.onload = function () {
        img.style.height = "40px";
        img.style.cssText = "height: 40px !important;"
        img.style.objectFit = "contain";
        document.getElementById(targetElementId).appendChild(img);
      };
    }
  }
};