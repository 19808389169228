import { createApp } from 'vue'
import App from './App.vue'

const rallyEventHeader = document.getElementById('rally-event-header');

const competitions = ['wrc', 'worldrx', 'erc'];

if (rallyEventHeader.dataset.competition && competitions.includes(rallyEventHeader.dataset.competition)) {
    const app = createApp(App);

    let competition = rallyEventHeader.dataset.competition;
    competition = competition === 'worldrx' ? 'wrx' : competition;
    app.provide('competition', competition);
    app.mount('#rally-event-header');
} else {
    console.log('wrong or missing data-competition');
}
