<template>
    <section class="wrc-eventheader">
        <div id="designation-banner">
            <div class="vAlignText logos">
                <a id="rally_event_header_competition_logo" :class="competition" :href="competitionBaseUrl" target="_blank"></a>
                <!-- <div class="slash"></div>
                <a href="https://www.fia.com/" target="_blank">
                    <img :src="urlPath('img/WRC_FIA_Logo.png')"
                         :srcset="urlPath('img/WRC_FIA_Logo@1x.png 1x') + ', ' + urlPath('img/WRC_FIA_Logo@2x.png 2x')"
                         alt="FIA logo">
                </a> -->
            </div>
            <div class="rally-tv" :class="competition">
                <a href="http://www.rally.tv/">
                    <img :src="urlPath('img/rallyTV.png')" alt="rally-tv logo">
                </a>
            </div>
            <!-- <div class="logo-plus" :class="competition">
                <img :src="pulseIcon" alt="Pulse Icon">
                <a id="rally_event_header_competition_plus_logo" class="vAlignText" :class="competition" :href="competitionBaseUrl + plusUrl" target="_blank">
                    <span :class="competition">watch live on</span>
                </a>
            </div> -->
            <div class="vAlignText bannerTitle">
                <a :href="competitionBaseUrl" target="_blank">
                    <span class="mobilehidden">{{ title }}&nbsp;|&nbsp; </span><span class="wrcromanbold">official event</span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>

import MainMixin from "./../mixins/main";

export default {
    name: 'RallyEventHeader',
    mixins: [MainMixin],
    computed: {
        title() {
            if (this.competition === 'wrx') {
                return 'FIA World RallyCross Championship';
            } else if (this.competition === 'erc') {
                return 'FIA European Rally Championship';
            }

            return 'FIA World Rally Championship';
        },
        pulseIcon() {
            if (this.competition === 'wrx') {
                return this.urlPath('img/live-icon-dark.gif');
            }

            return this.urlPath('img/WRC_live-icon.gif');
        }
    },
    created() {
        this.addHeadStyle();
    },
    mounted() {
        // this.loadSvgHtml(this.competitionLogoPath, 'rally_event_header_competition_logo');
        this.loadImage(this.competitionLogoPath, 'rally_event_header_competition_logo');
        // this.loadSvgHtml('img/platform/' + this.competition + '/logo_plus.svg.html', 'rally_event_header_competition_plus_logo');
    },
    methods: {
        addHeadStyle() {
            var newStyle = document.createElement('style');
            if(this.competition === 'wrx'){
                console.log("IN WRX")
                // newStyle.appendChild(document.createTextNode("\
                // @font-face {\
                //     font-family: 'rallyTv';\
                //     src: url(" + this.urlPath('fonts/rallyTv.ttf') + ");\
                // }\
                // \
                // @font-face {\
                //     font-family: 'rallyTvBold';\
                //     src: url(" + this.urlPath('fonts/rallyTvBold.ttf') + "); /* IE9 Compat Modes */\
                // }\
                // "));
                newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'rallyTv';\
                    src: url(" + this.urlPath('fonts/rallyTv.eot') + "); /* IE9 Compat Modes */\
                    src: url(" + this.urlPath('fonts/rallyTv.eot?#iefix') + ") format('embedded-opentype'), /* IE6-IE8 */ url(" + this.urlPath('fonts/rallyTv.woff') + ") format('woff'), /* Modern Browsers */ url(" + this.urlPath('fonts/rallyTv.ttf') + ") format('truetype'), /* Safari, Android, iOS */ url(" + this.urlPath('fonts/rallyTv.svg#svgFontName') + ") format('svg'); /* Legacy iOS */\
                }\
                \
                @font-face {\
                    font-family: 'rallyTvBold';\
                    src: url(" + this.urlPath('fonts/rallyTvBold.eot') + "); /* IE9 Compat Modes */\
                    src: url(" + this.urlPath('fonts/rallyTvBold.eot?#iefix') + ") format('embedded-opentype'), /* IE6-IE8 */ url(" + this.urlPath('fonts/rallyTvBold.woff') + ") format('woff'), /* Modern Browsers */ url(" + this.urlPath('fonts/rallyTvBold.ttf') + ") format('truetype'), /* Safari, Android, iOS */ url(" + this.urlPath('fonts/rallyTvBold.svg#svgFontName') + ") format('svg'); /* Legacy iOS */\
                }\
                "));
            } else {
                newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'wrcroman';\
                    src: url(" + this.urlPath('fonts/WRCROMAN.eot') + "); /* IE9 Compat Modes */\
                    src: url(" + this.urlPath('fonts/WRCROMAN.eot?#iefix') + ") format('embedded-opentype'), /* IE6-IE8 */ url(" + this.urlPath('fonts/WRCROMAN.woff') + ") format('woff'), /* Modern Browsers */ url(" + this.urlPath('fonts/WRCROMAN.ttf') + ") format('truetype'), /* Safari, Android, iOS */ url(" + this.urlPath('fonts/WRCROMAN.svg#svgFontName') + ") format('svg'); /* Legacy iOS */\
                }\
                \
                @font-face {\
                    font-family: 'wrcclean';\
                    src: url(" + this.urlPath('fonts/WRCCLEAN.eot') + "); /* IE9 Compat Modes */\
                    src: url(" + this.urlPath('fonts/WRCCLEAN.eot?#iefix') + ") format('embedded-opentype'), /* IE6-IE8 */ url(" + this.urlPath('fonts/WRCCLEAN.woff') + ") format('woff'), /* Modern Browsers */ url(" + this.urlPath('fonts/WRCCLEAN.ttf') + ") format('truetype'), /* Safari, Android, iOS */ url(" + this.urlPath('fonts/WRCCLEAN.svg#svgFontName') + ") format('svg'); /* Legacy iOS */\
                }\
                "));
            }

            document.head.appendChild(newStyle);
        }
    }
}
</script>


<style lang="less">
@media only screen and (max-width: 375px) {
    #rally_event_header_competition_logo.wrx svg {
        width: 130px!important;
    }
}
</style>

<style lang="less" scoped>
@import "assets/css/main.less";

@media only screen and (max-width: 375px) {
    .logos a.wrx {
        margin-right: 0!important;
    }
    #rally_event_header_competition_logo.wrx+div+a {
        img {
            height: 31px;
        }
    }
}

@media only screen and (min-width: 375px) and (max-width: 500px) {
    #rally-event-header {
        .rally-tv {
            img {
                width: 130px;
            }
        }
    }
}

@media only screen and (max-width: 710px) {
    #designation-banner .bannerTitle {
        order: 3;
        margin-top: 32px;
    }

    .wrc-eventheader {
        padding: 10px 10px;
        position: relative;
    }

    .logos {
        max-height: 40.97px; 
        a {
            &.wrx {
                width: 140px;
            }
            &.erc {
                margin-right: 15px;
            }
        }
    }
    #rally_event_header_competition_logo.erc+div {
        margin-right: 15px;
    }

    #rally_event_header_competition_logo.wrx + div {
        margin-right: 10px;
    }

    .logo-plus:after {
        border-bottom: 80px solid v-bind(hexCodePrimary);
    }

    .logo-plus.erc:after {
        border-bottom: 60px solid v-bind(hexCodePrimaryWrc);
    }

    .logo-plus a {
        top: 0;
    }

    .logo-plus > img {
        top: 2px;
    }
    .mobilehidden {
        display: none;
    }
}

@media only screen and (max-width: 879px) {

    .wrc-eventheader {
        max-height: 100%;
    }

    .bannerTitle {
        width: 100%;
        text-align: center;
        display: block;
        font-size: 1.5em;
        margin-top: 25px;
    }
    .logo-plus:after {
        width: 150px;
    }
    .logo-plus.wrc:after {
        border-bottom: 60px solid v-bind(hexCodePrimaryWrc)!important;
    }
    .logo-plus.wrx:after {
        border-bottom-width: 55px!important;
    }
}

@media only screen and (min-width: 880px) {
    #designation-banner > div:nth-child(1) {
        order: 1;
    }

    #designation-banner > div:nth-child(2) {
        order: 3;
    }

    #designation-banner > div:nth-child(3) {
        order: 2;
    }

    #designation-banner .bannerTitle {
        font-size: 16px;
    }
}

</style>

<style lang="less">
#rally-event-header {
    .rally-tv {
        img {
            width: 220px;
        }
    }
}
#rally_event_header_competition_logo {
    &.wrc {
        svg {width: 90px; max-height: 100%;}
        path {
            &.wrc_text, &.fia_world_rally_background, &.fia_emblem, &.championship_text {fill: #fff}
        }
        + div {height: 36.47px;
            + a {height: 37px; margin-top: 1.5px;}
        }
    }
    &.wrx {
        svg {width: 150px; max-height: 100%;}
        path {
            &.world_vertical_text, &.r_big_text, &.x_big_foreground_text, &.fia_emblem, &.championship_text {fill: #fff}
            &.world_text, &.rallycross_text, &.x_big_left_top_text, &.x_big_right_bottom_text {fill: #02f3e9}
        }
        + div {height: 30px;
            + a {height: 31px; margin-top: 2px;}
        }
    }
    &.erc {
        svg {width: 106px; max-height: 100%;}
        path {
            &.erc_text, &.european_rally_background, &.fia_emblem, &.championship_text {fill: #fff}
            &.star {fill: #0072ce}
        }
        + div {height: 34px; margin-top: 8px;
            + a {height: 36px; margin-top: 10.5px;}
        }
    }
}

#rally_event_header_competition_plus_logo {
    &.wrc {
        svg {width: 58px; max-height: 100%;}
        path {
            &.wrc_text, &.plus_text {fill: #fff}
        }

    }
    &.wrx {
        svg {width: 30px; max-height: 100%;}
        path {
            &.rx_text, &.plus_text {fill: v-bind(hexCodeSecondary)}
        }
    }
    &.erc {
        svg {width: 58px; max-height: 100%;}
        path {
            &.wrc_text, &.plus_text {fill: #fff}
        }
    }
}
</style>
